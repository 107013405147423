.rt-th {
	display: flex;
}

.rt-tr-group {
	cursor: pointer !important;
}

h3 {
	color: black;
	padding: 20px 0;
}

body {
	background-color: #f9fbfc;
}

.container-promos {
	/* height: 100vh; */
	overflow: scroll;
	height: 100%;
}
