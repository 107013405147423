.cars-container,
.company-container {
	margin-top: 40px;
}

.users-car-table-header-data {
	width: 20%;
}

.users-car-table-row-data {
	width: 20%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}

.btn {
	margin-left: 10px;
}

.document-header,
.car-images-header {
	margin-top: 10px;
	margin-bottom: 10px;
}

h4 {
	font-weight: bold;
	text-transform: uppercase;
}
