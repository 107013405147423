/* Made with love by Mutiullah Samim*/
.login {
	background-image: url('https://www.girocarshare.com/images/uploads/heroimage.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100vmax;
}

h3 {
	color: whitesmoke !important;
	font-weight: 700;
	font-size: 1.75rem;
	text-align: center;
}

h1 {
	color: ghostwhite !important;
	font-weight: 700;
	font-size: 2rem;
	text-align: center;
}

.container {
	height: 100vh;
	align-content: center;
}

.card {
	height: 100vmax;
	margin-top: auto;
	margin-bottom: auto;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.input-group-prepend span {
	width: 50px;
	background-color: #ffc312;
	color: black;
	border: 0 !important;
}

input:focus {
	outline-width: 0 !important;
	box-shadow: 0 0 0 0 !important;
}

.login_btn {
	color: black;
	background-color: #ffc312;
	width: 100%;
}

.login_btn:hover {
	color: black;
	background-color: white;
}

.links {
	color: white;
}

.links a {
	margin-left: 4px;
}

.error-msg {
	margin-top: 70px;
	color: orangered;
	font-family: Lato, serif;
}

.info-text {
	font-family: Lato, serif;
	color: white;
	margin-bottom: 5px;
	display: block;
}
