.dashboard {
	/* height: 100%;
    width: 100%;
    max-width: 100%; */
	position: relative;
	background: transparent;
	position: relative;
	background: transparent;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.nav-container {
	width: 15%;
}

.container-dash {
	width: 100%;
}

a,
a:visited {
	color: white !important;
	text-decoration: none !important;
}

a:hover {
	color: orange;
}

.nav {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.container {
	max-width: 100%;
	height: 100% !important;
	padding: 0;

	display: flex;
	justify-content: flex-start;
	align-items: center;
}

h1 {
	padding: 20px;
}

.info-text-dash {
	padding: 20px;
	font-size: 1.3rem;
}

.option-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
}

a.option {
	cursor: pointer;
	height: 17em !important;
	width: 30%;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	flex-direction: column !important;
	background: white;
	border-radius: 4px !important;
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2) !important;
	color: black !important;
}

.icon {
	font-size: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.name {
	text-align: center;
	font-size: 1.6rem;
	margin-top: 20px;
}
