/* PARTNER FORM */

.btn {
	margin-left: 10px;
}

.save-btn {
	margin-top: 20px;
}

.images-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.back-btn {
	margin-bottom: 20px;
	font-size: 1.6rem;
}

.back-btn i {
	padding-right: 10px;
}

a.option-partner-details {
	cursor: pointer !important;
	display: block !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	color: black !important;
	height: 35px !important;
}

h4 {
	font-weight: bold;
	text-transform: uppercase;
}

span {
	padding: 0;
}
