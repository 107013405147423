.images-before,
.images-after {
	margin-top: 20px;
}

.btn {
	margin-left: 10px;
	margin-top: 10px;
}

.back-btn {
	width: 200px;
}

h4 {
	font-weight: bold;
	text-transform: uppercase;
}

h5 {
	font-weight: bold;
	text-transform: uppercase;
}
