body {
	margin: 0;
	padding: 0;
	font-family: 'Lato', sans-serif;
	height: 100vh;
	width: 100vw;
	position: relative;
	background-color: #f9fbfc;
}

.search label {
	display: block;
}

.search input[type='checkbox'] {
	margin: 0 6px;
}

.crs:hover {
	cursor: pointer;
}

.crs-border:hover {
	border-bottom: 1px solid #000;
}

.crs:hover {
	cursor: pointer;
}

hr {
	width: 100%;
	height: 2px;
	background-color: #f9fbfc;
}

.card-container {
	width: 100%;
	padding: 20px;
	background-color: white;
	margin: 20px;
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

/* INPUT AND FORM STYLES */
span.label {
	width: 190px;
	padding: 0 5px 0 0;
}

span,
p {
	font-size: 1.2rem;
	padding: 0;
}

.user-data {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 0;
}

.buttons {
	margin-left: 20px;
}

.input-fields {
	padding: 10px 0;
}

.radio {
	margin-right: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.radio label {
	display: block;
	padding-right: 10px;
	margin: 0;
}

input {
	border: none;
	outline: none;
	border-bottom: 1px solid black;
	font-size: 1.2rem;
	/* width: 100%; */
}

.row {
	width: 100%;
}

h3 {
	padding: 0;
}

/* HEADER */

.nav {
	background: #4096ee;
	height: 60px;
	display: flex;
	align-items: center;
	font-size: 1.3rem;
}

.nav-link {
	color: white !important;
}

.nav-link:hover {
	color: orange !important;
}

.logout,
.logout:hover {
	cursor: pointer;
	color: black !important;
}
