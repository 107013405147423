img {
	box-shadow: 0 0 18px 0 #b8b3b7;
	border-radius: 6px;
	max-width: 300px;
}

.del-btn {
	margin-left: 40px;
}

.car-images {
	margin-top: 20px;
}

.car-images h4 {
	margin: 20px 0;
}

.car-images-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.car-images-container img {
	margin-right: 20px;
}
