/* USERS FORM */

.container-user-details {
}

.btn {
	margin-left: 10px;
}

.save-btn {
	margin-top: 20px;
}

.images-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.back-btn {
	margin-bottom: 20px;
	font-size: 1.6rem;
}

.back-btn i {
	padding-right: 10px;
}

a.option-user-details {
	cursor: pointer !important;
	display: block !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	color: black !important;
	height: 35px !important;
}

h4 {
	font-weight: bold;
	text-transform: uppercase;
}

span {
	padding: 0;
}

.house-number {
	width: 120px;
}

.documents {
}

.image-header {
	margin-top: 20px;
	height: 85px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.image-input {
	width: 250px;
	border-bottom: none;
}

.email-link,
.email-link:hover,
.email-link:active,
.email-link:visited,
.phone,
.phone:hover,
.phone:active,
.phone:visited {
	color: black !important;
	font-size: 1.2rem;
	text-decoration: none;
	cursor: pointer;
}

.phone {
	margin-left: 10px;
}

.input-fields input {
	width: 250px;
}

/* TABLE STYLES */

.users-car-table,
.users-bookings-table {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.users-car-table-header,
.users-car-table-row,
.users-bookings-table-header,
.users-bookings-table-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid black;
	margin-bottom: 15px;
	padding: 10px 0;
}

.users-car-table-row,
.users-bookings-table-row {
	cursor: pointer;
}

/* USERS BOOKINGS */

.bookings-container {
	margin-top: 50px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.bookings-container h4 {
	margin-right: 40px;
}

.users-bookings-table-header-data {
	width: 25%;
}

.users-bookings-table-row-data {
	width: 25%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px;
}
